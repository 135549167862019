.react-autosuggest__container {
	margin-top: 12px;
}
/* .react-autosuggest__container--open {} */
.react-autosuggest__input {
	font-family: "Proxima Nova W01", "Rubik", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
	width: 450px;
    display: flex;
    flex-direction: column;
	justify-content: center;
	font-weight: 300;
	padding: .5rem .7rem;
    font-size: .9rem;
    line-height: 1.25;
    color: #464a4c;
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
    border: 1px solid #cecece;
    border-radius: .25rem;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.react-autosuggest__input:focus {
	outline: none;
}
/* .react-autosuggest__input--open {} */
/* .react-autosuggest__input--focused {} */
.react-autosuggest__suggestions-container {
	overflow: hidden;
	width: 500px;
	border-radius: .25rem;
}
.react-autosuggest__suggestions-container--open {
	max-height: 300px;
	overflow-y: scroll;
	border: 1px solid #cecece;
}
.react-autosuggest__suggestions-list {
	margin: 0;
	padding: 0;
}
.react-autosuggest__suggestion {
	list-style-type: none;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.react-autosuggest__suggestion div {
	flex: 1 1;
    text-align: left;
    color: #3E4B5B;
	transition: all 0.2s ease;
	padding: 4px 12px;
}
/* .react-autosuggest__suggestion--first {} */
.react-autosuggest__suggestion--highlighted {
	cursor: pointer;
}
.react-autosuggest__suggestion--highlighted div {
	color: #047bf8;
}
/* .react-autosuggest__section-container {} */
/* .react-autosuggest__section-container--first {} */
/* .react-autosuggest__section-title {} */
