.search-with-suggestions-w {
	display: none
}

.search-with-suggestions-w.active {
	display: block
}

.search-with-suggestions-w .search-with-suggestions-modal {
	background-color: #fff;
	border-radius: 4px;
	-webkit-box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
	padding: 30px;
	position: relative
}

.search-with-suggestions-w .search-with-suggestions-modal .close-search-suggestions {
	font-size: 14px;
	border-radius: 4px;
	position: absolute;
	top: 50%;
	right: 0px;
	cursor: pointer;
	padding: 2px;
	background-color: #e65252;
	color: #fff;
	line-height: 0.9;
	vertical-align: middle;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%)
}

.search-with-suggestions-w .search-with-suggestions-modal .close-search-suggestions i {
	vertical-align: middle
}

.search-with-suggestions-w .search-with-suggestions-modal .close-search-suggestions:hover {
	background-color: #b71b1b;
	color: #fff
}

.search-with-suggestions-w .search-with-suggestions-modal .element-search {
	position: relative;
	margin: 0px;
	margin-bottom: 30px
}

.search-with-suggestions-w .search-with-suggestions-modal .element-search:before {
	font-family: 'osfont' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	position: absolute;
	left: 6px;
	top: 48%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	font-size: 16px;
	content: "\e92c";
	color: rgba(0, 0, 0, 0.3)
}

.search-with-suggestions-w .search-with-suggestions-modal .element-search input {
	border: none;
	-webkit-box-shadow: none;
	box-shadow: none;
	border-radius: 0px;
	padding: 8px 15px 8px 31px;
	display: block;
	width: 100%;
	outline: none;
	border: none;
	box-shadow: none;
	border-bottom: 1px solid #111
}

.search-with-suggestions-w .search-with-suggestions-modal .element-search input::-webkit-input-placeholder {
	color: rgba(0, 0, 0, 0.5)
}

.search-with-suggestions-w .search-with-suggestions-modal .element-search input:-ms-input-placeholder {
	color: rgba(0, 0, 0, 0.5)
}

.search-with-suggestions-w .search-with-suggestions-modal .element-search input::-ms-input-placeholder {
	color: rgba(0, 0, 0, 0.5)
}

.search-with-suggestions-w .search-with-suggestions-modal .element-search input::placeholder {
	color: rgba(0, 0, 0, 0.5)
}

.search-with-suggestions-w .search-suggestions-group+.search-suggestions-group {
	margin-top: 20px
}

.search-with-suggestions-w .search-suggestions-group:last-child .ssg-items {
	margin-bottom: 0px
}

.search-with-suggestions-w .search-suggestions-group .ssg-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 2px solid rgba(0, 0, 0, 0.07);
	margin-bottom: 15px;
	padding-bottom: 5px
}

.search-with-suggestions-w .search-suggestions-group .ssg-header .ssg-icon {
	color: #047bf8;
	font-size: 20px;
	margin-right: 15px
}

.search-with-suggestions-w .search-suggestions-group .ssg-header .ssg-name {
	color: #047bf8;
	font-family: "Avenir Next W01", "Proxima Nova W01", "Rubik", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
	font-weight: 500;
	font-size: .99rem
}

.search-with-suggestions-w .search-suggestions-group .ssg-header .ssg-info {
	margin-left: auto;
	color: rgba(0, 0, 0, 0.4);
	font-size: .8rem
}

.search-with-suggestions-w .ssg-content .ssg-nothing-found {
	color: rgba(0, 0, 0, 0.4);
	padding: 0px 20px;
	text-align: center;
	font-size: .8rem;
	display: none
}

.search-with-suggestions-w .ssg-content .ssg-nothing-found .icon-w {
	margin-bottom: 10px;
	font-size: 20px;
	color: rgba(0, 0, 0, 0.2)
}

.search-with-suggestions-w .ssg-items {
	margin-bottom: 25px
}

.search-with-suggestions-w .ssg-items .ssg-item .item-name span {
	background-color: #FFF0B7
}

.search-with-suggestions-w .ssg-items.ssg-items-blocks {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap
}

.search-with-suggestions-w .ssg-items.ssg-items-blocks .ssg-item {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 50%;
	flex: 0 0 50%;
	padding: 10px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	text-align: center
}

.search-with-suggestions-w .ssg-items.ssg-items-blocks .ssg-item:hover {
	text-decoration: none
}

.search-with-suggestions-w .ssg-items.ssg-items-blocks .ssg-item:hover .item-icon,
.search-with-suggestions-w .ssg-items.ssg-items-blocks .ssg-item:hover .item-media {
	-webkit-transform: rotate(-15deg);
	transform: rotate(-15deg)
}

.search-with-suggestions-w .ssg-items.ssg-items-blocks .ssg-item:hover .item-name {
	-webkit-transform: translateY(-5px);
	transform: translateY(-5px);
	color: #047bf8
}

.search-with-suggestions-w .ssg-items.ssg-items-blocks .ssg-item:nth-child(even) {
	border-left: 1px solid rgba(0, 0, 0, 0.05)
}

.search-with-suggestions-w .ssg-items.ssg-items-blocks .ssg-item:last-child,
.search-with-suggestions-w .ssg-items.ssg-items-blocks .ssg-item:nth-last-child(2) {
	border-bottom: none
}

.search-with-suggestions-w .ssg-items.ssg-items-blocks .ssg-item .item-icon {
	font-size: 20px;
	margin-bottom: 5px;
	color: #047bf8;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease
}

.search-with-suggestions-w .ssg-items.ssg-items-blocks .ssg-item .item-media {
	height: 30px;
	width: 30px;
	margin: 0px auto;
	background-size: cover;
	margin-bottom: 10px;
	border-radius: 50%;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease
}

.search-with-suggestions-w .ssg-items.ssg-items-blocks .ssg-item .item-name {
	font-size: .8rem;
	text-decoration: underline;
	color: #3E4B5B;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease
}

.search-with-suggestions-w .ssg-items.ssg-items-list .ssg-item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 0px 10px 7px 0px;
	margin-bottom: 7px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.05)
}

.search-with-suggestions-w .ssg-items.ssg-items-list .ssg-item:hover {
	text-decoration: none
}

.search-with-suggestions-w .ssg-items.ssg-items-list .ssg-item:hover .item-media {
	-webkit-transform: scale(1.1);
	transform: scale(1.1)
}

.search-with-suggestions-w .ssg-items.ssg-items-list .ssg-item:hover .item-name {
	-webkit-transform: translateX(3px);
	transform: translateX(3px);
	color: #047bf8
}

.search-with-suggestions-w .ssg-items.ssg-items-list .ssg-item:last-child {
	border-bottom: none
}

.search-with-suggestions-w .ssg-items.ssg-items-list .ssg-item .item-media {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 30px;
	flex: 0 0 30px;
	height: 30px;
	background-size: cover;
	margin-right: 15px;
	border-radius: 50%;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease
}

.search-with-suggestions-w .ssg-items.ssg-items-list .ssg-item .item-name {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	text-align: left;
	color: #3E4B5B;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease
}

.search-with-suggestions-w .ssg-items.ssg-items-boxed .ssg-item {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 6px 8px;
	border: 1px solid #C4D1E1;
	-webkit-box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.07);
	box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.07);
	border-radius: 4px;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease
}

.search-with-suggestions-w .ssg-items.ssg-items-boxed .ssg-item:hover {
	text-decoration: none;
	border: 1px solid #a2b7d0;
	-webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.12);
	box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.12);
	-webkit-transform: translateY(-2px);
	transform: translateY(-2px)
}

.search-with-suggestions-w .ssg-items.ssg-items-boxed .ssg-item:hover .item-name {
	color: #047bf8
}

.search-with-suggestions-w .ssg-items.ssg-items-boxed .ssg-item+.ssg-item {
	margin-top: 8px
}

.search-with-suggestions-w .ssg-items.ssg-items-boxed .ssg-item .item-media {
	-webkit-box-flex: 0;
	-ms-flex: 0 0 30px;
	flex: 0 0 30px;
	height: 30px;
	background-size: cover;
	margin-right: 15px
}

.search-with-suggestions-w .ssg-items.ssg-items-boxed .ssg-item .item-name {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	text-align: left;
	color: #3E4B5B;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease
}

.search-with-suggestions-w.over-search-field {
	position: absolute;
	width: 420px;
	z-index: 1020;
	-webkit-transform: translate(-21px, -5px);
	transform: translate(-21px, -5px)
}

.search-with-suggestions-w.over-search-field .search-with-suggestions-modal {
	padding-top: 5px;
	-webkit-box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.4);
	box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.4)
}

.search-with-suggestions-w.screen-center {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1050;
	overflow-x: hidden;
	overflow-y: auto;
	background-color: rgba(0, 0, 0, 0.4)
}

.search-with-suggestions-w.screen-center .search-with-suggestions-modal {
	top: 15%;
	margin: 0px auto;
	width: 350px;
	position: relative;
	-webkit-box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.4);
	box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.4);
	margin-bottom: 20px
}

.search-with-suggestions-w .searching, .fetching-load {
	text-align: center;
	padding: 15px;
}

.search-with-suggestions-w .searching img {
	width: 30px;
	height: 30px;
}